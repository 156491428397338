/* If you need to add @import statements, do so up here */

@import "jit-refresh.css";
/* triggers frontend rebuilds */
@import "fonts.css";
@import "./nav.css";

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* debugger * {
  border: red 1px solid;
} */

@layer base {
  body {
    @apply font-body text-base;
  }

  /*
    Responsive helpers
  */
  .responsive-wrapper {
    @apply px-8 md:px-16 lg:px-24;
    &.top {
      @apply py-10 md:pt-8 md:pb-16 lg:pb-24;
    }
    &.mid {
      @apply py-10 md:py-16 lg:py-28;
    }
    &.bottom {
      @apply py-10 md:pt-16 pb-10;
    }
    &.small {
      @apply py-10 md:py-16;
    }
  }

  /* this is a debug rule -- turn it on if you need it to see what top-level things aren't responsive */
  main
    > *:not(.responsive-wrapper):not(.rip-component):not(
      .skip-responsive-wrapper
    ):not([data-controller="top"]) {
    border: 5px solid lime;
  }

  /*
    Button-links
  */
  .btn {
    @apply text-sm
    lg:text-base
    block
    leading-snugger
    font-bold
    text-center
    text-nowrap
    font-mono
    uppercase
    rounded-full
    py-3
    px-6
    hover:outline
    outline-2 
    outline-offset-2
    disabled:bg-gray-200
    disabled:border-gray-200
    disabled:text-gray-600
    md:w-fit
    w-full
    cursor-pointer
    transition-colors
    duration-300
    ease-in-out;

    &.primary {
      @apply bg-salmon-600
      text-white
      outline-salmon-600
      hover:text-white
      hover:bg-salmon-700
      disabled:bg-gray-200
      disabled:border-gray-200
      disabled:text-gray-600
      text-white;

      &.high-contrast {
        @apply text-gray-800
        bg-pineapple-400
        outline-pineapple-400
        hover:text-gray-700
        hover:bg-pineapple-300
        text-center
        w-fit
        disabled:bg-gray-300
        disabled:border-gray-300
        disabled:text-gray-700;
      }
    }

    &.secondary {
      @apply bg-transparent
      text-gray-800
      border-2
      border-gray-800
      w-full
      md:w-fit
      outline-gray-800
      disabled:bg-gray-600
      disabled:border-gray-600
      disabled:text-gray-200;

      &.high-contrast {
        @apply border-gray-100
        text-gray-100
        w-full
        md:w-fit
        outline-gray-100
        hover:bg-gray-600/60
        hover:text-gray-100
        hover:border-gray-100
        disabled:bg-gray-300
        disabled:border-gray-300
        disabled:text-gray-700;
      }

      &.w_icon {
        @apply flex flex-row items-center gap-4 w-fit;
      }
    }

    &.small {
      @apply text-sm
      leading-normal
      py-2
      px-4;
    }
  }

  /*
    Text links
  */
  a.link {
    &.primary {
      @apply font-bold hover:text-salmon-400 hover:underline underline-offset-2 decoration-pineapple-500;
    }
    &.secondary {
      @apply font-bold text-gray-100 hover:text-gray-300;
    }
    &.light {
      @apply font-normal hover:text-salmon-400 hover:underline underline-offset-2 decoration-pineapple-500;
    }
  }

  .lower-highlight {
    @apply underline decoration-4 underline-offset-4 decoration-pineapple-400 transition-all ease-in-out duration-300 hover:underline-offset-[6px] hover:decoration-[6px] hover:text-salmon-400;
  }

  .no-highlight {
    @apply bg-none;
  }

  a.text-black.on-white,
  a.text-black.on-pink {
    @apply font-body font-bold;
  }

  a[class^="text-salmon"].on-black {
    @apply font-body font-bold hover:text-pineapple-400;
  }

  a.text-white.on-black {
    @apply font-body font-bold hover:text-salmon-600;
  }

  /* TYPOGRAPHY – design headers */
  /* figma header 1 */
  .header-biggest {
    @apply font-display uppercase font-black leading-none text-3xl md:text-4xl lg:text-5xl xl:text-6xl;
  }

  /* figma header 2 */
  .header-bigger {
    @apply font-display uppercase font-black leading-none text-2xl md:text-3xl lg:text-4xl xl:text-5xl;
  }

  /* figma header 3 */
  .header-big {
    @apply font-body font-extrabold leading-tight text-xl md:text-2xl xl:text-3xl;
  }

  /* figma header 4 */
  .header-large {
    @apply font-body font-extrabold leading-tight text-lg md:text-xl;
  }

  /* figma header 5 */
  .header-medium {
    @apply font-display font-semibold leading-normal text-md md:text-lg;
  }

  /* figma header 6 */
  .header-small {
    @apply font-mono leading-tight text-sm uppercase;
  }

  /* figma figma body large. mobile size text is assumed. */
  .body-large {
    @apply font-body text-base leading-snug md:leading-normal md:text-md lg:text-lg;
  }

  /* figma figma body */
  .body-normal {
    @apply font-body leading-normal text-base lg:text-md;
  }

  /* figma figma body small */
  .body-small {
    @apply font-body leading-relaxed tracking-wide text-sm lg:text-base;
  }

  /* BLOG Markdown styles */
  .md-style {
    --center-highlight-color: theme("colors.pineapple.400");

    p {  
      @apply text-sm md:text-base mb-6 font-body leading-relaxed w-full;
      &.has-one-image { 
        @apply max-w-lg aspect-auto mx-auto mb-4 overflow-hidden rounded-lg; 
        img { @apply object-cover object-center w-full h-full;}
      }
      &.has-two-images { @apply grid grid-cols-2 gap-4 mb-4;}
      &.has-multiple-images { @apply grid grid-cols-2 md:grid-cols-3 gap-4 mb-4; }
      img { @apply object-cover w-full h-full;}
    }
    img:not([src*="problem-with-curosity" i]) {
      @apply m-0 px-0 w-full h-auto rounded-lg bg-white shadow-md;
    }
    em { @apply mr-1; }
    a { @apply font-bold underline decoration-4 underline-offset-4 decoration-pineapple-400 transition-all ease-in-out duration-300 hover:underline-offset-[6px] hover:decoration-[6px] hover:!text-salmon-400; }
    a:hover { @apply text-black; }
    h1 { @apply text-3xl lg:text-4xl font-body font-extrabold mb-8; }
    h2 { @apply text-2xl lg:text-3xl center-highlight w-fit font-body first:mt-0 mt-8 mb-6 font-bold; }
    h3 { @apply text-lg lg:text-xl font-body font-bold mt-8 mb-4; }
    h4 { @apply text-md font-body font-normal; }
    h5 { @apply text-base font-body font-normal; }
    h6 { @apply text-base font-body font-normal; }
    blockquote {
      @apply mb-6 lg:m-16 py-8 px-8 lg:px-12 bg-oyster-100 border-l-4 border-oyster-300;
      p { @apply text-sm lg:text-[16px] mb-4 last:mb-0 text-oyster-800 font-mono leading-relaxed; }
    }
    div.highlight { @apply overflow-x-auto my-6 rounded-md shadow-sm; }
    ol { @apply list-decimal ml-8; }
    ul { @apply list-disc ml-4 lg:ml-10 mb-4 lg:my-8; }
    li { @apply pl-1 mb-3 text-sm lg:text-base leading-normal; }
    li::marker { @apply text-salmon-500; }
    hr { @apply my-12 bg-pineapple-400; }
    strong { @apply font-bold; }
    .footnotes {
      ol { @apply ml-0; }
      li { @apply flex flex-row mb-0; }
      a { @apply ml-2; }
      p { @apply mb-1; }
    }
    code.highlighter-rouge {
      @apply px-1 rounded-sm text-[0.9em] bg-[#D1D5DB];
    }

    &.context {
      p:first-of-type {
        @apply line-clamp-6;
      }
      p { @apply hidden; }
    }
  }

  .post {
    .tags-wrapper { @apply flex flex-row flex-wrap justify-center gap-2 border-t border-oyster-300 pt-4; }
    .title { @apply header-bigger normal-case text-white text-center; }
    .subhead { @apply body-large text-white text-center; }
    
  }
/* Case Study Markdown styles */
  .case_study {
    header {
      p img { @apply md:fixed w-full object-cover -z-10 md:-mt-[110px] bg-repeat-y; }
      
    }
    .cs-md-style {
      --center-highlight-color: theme("colors.pineapple.400");
      h1 { @apply text-3xl lg:text-4xl font-body font-extrabold mb-8; }
      h2 { @apply text-2xl lg:text-3xl center-highlight w-fit font-body first:mt-0 mt-8 mb-6 font-bold; }
      h3 { @apply header-bigger center-highlight w-max; }
      h4 { @apply header-big font-black text-2xl mb-4; }
      h5 { @apply text-base font-body font-normal; }
      h6 { @apply text-base font-body font-normal; }
      .block-header {@apply mb-6 mt-8; }
      .challenge,
      .solution,
      .result { @apply mb-8 lg:mb-12; }
      img { @apply my-6 px-0 w-full h-auto rounded-lg bg-white shadow-md max-w-4xl aspect-auto mx-auto; }
      .intro p > img:first-of-type { @apply mx-auto w-full; }

      p.horizontal,
      p.vertical { @apply flex flex-col gap-4 lg:gap-8; }

      p.horizontal { 
        @apply md:flex-row; 
        img { @apply object-cover w-full h-auto; }
      }
      p {  
        @apply text-base lg:text-md mb-4 font-body leading-relaxed w-full;
        &.has-one-image { 
          @apply max-w-2xl aspect-auto mx-auto mb-4 overflow-hidden rounded-lg; 
          img { @apply object-cover object-center w-full h-full;}
        }
        &.has-two-images { @apply grid grid-cols-2 gap-4 mb-4;}
        &.has-multiple-images { @apply grid grid-cols-2 md:grid-cols-3 gap-4 mb-4; }
        img { @apply object-cover w-full h-full;}
      }
      ol { @apply list-decimal ml-8; }
      ul { @apply list-disc pl-8 lg:ml-6 mb-4 w-full; }
      li { @apply pl-1 mb-3 text-[16px] lg:text-md leading-normal; }
      li::marker { @apply text-salmon-500; }
      li:first-child { @apply pt-2; }
      blockquote { 
        @apply lg:w-5/6 mx-auto lg:my-16; 
        p { @apply border-l-4 border-salmon-400 pl-8 body-large text-xl lg:text-2xl leading-tight mx-auto font-semibold; }
      }
      cite { @apply block mt-4 body-normal font-normal mx-auto not-italic; }
    }
  }
}
/* Visual Study styles */
.visual_study {
  header {
    img { @apply md:fixed w-full object-cover -z-10 md:-mt-[110px] bg-repeat-y; }
  }
}

/* Media Page tag styles */
.media-tag-list {
  li, a {
    @apply py-2 px-3 font-mono text-[16px] border border-gray-900;
    &.article-type {@apply bg-salmon-300 text-gray-900 rounded-lg;}
    &.podcast-type {@apply bg-pineapple-400 text-gray-900 rounded-tl-xl rounded-br-xl;}
    &.video-type {
      @apply bg-salmon-600 text-white;
    }
  }
}


.hotp-style {
  --center-highlight-color: theme("colors.pineapple.400");

  p { @apply body-normal mb-8; }
  a { @apply font-bold underline decoration-4 underline-offset-4 decoration-pineapple-400 transition-all ease-in-out duration-300 hover:underline-offset-[6px] hover:decoration-[6px] hover:text-salmon-400; }
}

@layer components {
  :root {
    --center-highlight-color: theme("colors.pineapple.400");
    --black: theme("colors.black");
    --white: theme("colors.white");
  }

  html {
    @apply scroll-smooth overflow-y-scroll box-border;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "roboto-mono",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    /* https://developer.mozilla.org/en-US/docs/Web/CSS/line-height#prefer_unitless_numbers_for_line-height_values */
    line-height: 1.2;
  }

  main {
    @apply font-body;
  }

  .markdown {
    p,
    li { @apply mb-4; }
    ul { @apply list-outside; }
    a { @apply font-body font-bold text-salmon-600 hover:text-salmon-500; }
  }

  /* tag, category, enhanced-tag */
  .pill {
    @apply font-mono text-xxs p-2 w-auto h-fit whitespace-nowrap leading-none;
    &.selected { 
      @apply bg-pineapple-400 text-black;
      
      &.design { @apply bg-flamingo-200 text-black border border-flamingo-200; }
      &.development { @apply bg-salmon-600 text-white border border-salmon-600; }
      &.consulting { @apply bg-pineapple-400 text-black border border-pineapple-400; }
      &.software { @apply bg-oyster-200 text-black border border-oyster-200; }
    }
    
  }

  .center-highlight {
    --highlight-color: var(
      --center-highlight-color,
      theme("colors.pineapple.400")
    );
    background: linear-gradient(
      transparent 0 25%,
      var(--highlight-color) 25% 85%,
      transparent 85% 90%
    );
    background-position: 100% 0em;
    @apply bg-auto;
  }

  .border-sticker { @apply border-2 border-white shadow-lg; }
  .border-sticker-slim { @apply border-sticker border; }
  .social { @apply fill-current; }
  .card {
    .cover-photo { @apply flex-none aspect-square mb-2; }
  }

  .authors-block {
    @apply flex flex-col;

    & .author-avatar { @apply aspect-square rounded-full h-full; }
    & .author-name { @apply font-bold not-italic; }
    & .published-date { @apply font-mono uppercase leading-none; }
    &.small {
      & .author-avatar { @apply w-10 min-w-10 max-w-10 border-sticker-slim; }
      & .author-name { @apply body-normal; }
      & .published-date { @apply text-xxs; }
      cite {
        @apply gap-2;
      }
    }
    &.large {
      & .author-avatar { @apply w-12 min-w-12 max-w-12 border-sticker; }
      & .author-name { @apply text-md font-normal text-gray-900; }
      & .published-date { @apply text-base; }
      cite {
        @apply border-y border-oyster-300 py-6;
        div {
          @apply ml-6 first:ml-2 mb-2;
        }
      }
    }
  }
}

.scratched-underline {
  @apply relative;
  &::after {
    @apply hidden md:block w-full h-full absolute left-[-30px] bottom-[-65%] content-[''] bg-no-repeat;
    background-image: url("/images/scratched-underline.png");
  }
}

.culture-values {
  b { @apply underline decoration-4 underline-offset-4 decoration-salmon-400; }
  .polaroid { @apply odd:-rotate-3; }
}


/****** Helpers Classes ******/

.brlarge { @apply block mb-8; }

.reveal { @apply relative opacity-0 transition-all ease-in-out;
  transform: translateY(150px);
}

.reveal.active { 
  transform: translateY(0);
  opacity: 1;
}

.submitting {
  @apply bg-blue-500;
}

.submitted {
  @apply bg-green-500;
}

hr {
  border: none;
  border-top: 2px dotted #bbb;
  margin: 3.375rem 0;
}

/*
  Most screenreaders will ignore `del`, `ins` elements as, well, deleted text.

  This ensures that a screenreader will still announced it while also marking it as deleted.
*/
del::before,
del::after {
  clip-path: inset(100%);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

del::before {
  content: " [deletion start] ";
}

del::after {
  content: " [deletion end] ";
}

ins {
  text-decoration: none;
}

ins::before,
ins::after {
  clip-path: inset(100%);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

ins::before {
  content: " [insertion start] ";
}

ins::after {
  content: " [insertion end] ";
}

.blog-card {
  /* This allows the alternating colors. */

  &:nth-of-type(5n + 1) { & .content { @apply bg-oyster-100; } }
  &:nth-of-type(5n + 2) { & .content { @apply bg-flamingo-100; } }
  &:nth-of-type(5n + 3) { & .content { @apply bg-oyster-200; } }
  &:nth-of-type(5n + 4) { & .content { @apply bg-flamingo-200; } }
  &:nth-of-type(5n + 5) { & .content { @apply bg-white; } }
}

.design-method-content {
  ol {
    list-style-type: decimal;

    & ol {
      list-style-type: lower-alpha;
    }
  }

  ul {
    list-style-type: initial;
  }
}

.responsive-wrapper.blog-post {
  @apply px-mobileAndAboveSidePadding
  tabletAndAbove:px-narrowTabletPadding
  desktopAndAbove:px-narrowDesktopPadding;
}

.pagination-link {
  &[aria-current="page"] {
    @apply font-bold shadow-pineapple-500;
  }
}

.flex-centered {
  @apply flex justify-center items-center;
}

.flex-centered-col {
  @apply flex flex-col justify-center items-center;
}

.how-step-content,
.quick-win-content {
  ul,
  ol {
    list-style: initial;
    padding-left: 1rem !important;
    margin: initial;
  }

  a {
    @apply underline;
  }
}

.quick-win-card {
  &:nth-of-type(4n + 1) {
    @apply bg-gray-100;
  }
  &:nth-of-type(4n + 2) {
    @apply bg-flamingo-100;
  }
  &:nth-of-type(4n + 3) {
    @apply bg-pineapple-400;
  }
  &:nth-of-type(4n + 4) {
    @apply bg-oyster-300;
  }
}

/* ------------- For Conf App ----------------- */
.conf {
  @apply w-full transition-transform delay-300 ease-in-out;

  input[type="email"] {
    @apply border border-gray-300 focus:outline-2 focus:outline-salmon-500 rounded-lg p-4 w-full mt-6 mb-2 text-gray-900 text-md placeholder:text-gray-600;
  }

  .btn-mobile {
    @apply object-cover text-center text-white rounded-xl col-span-1 hover:bg-salmon-700 p-4 font-bold text-md shadow-md shadow-oyster-400 w-full;

    &.pink {
      @apply bg-flamingo-200 w-full text-black min-h-20;
    }
    &.yellow {
      @apply bg-pineapple-400 text-black w-full min-h-20;
    }
    &.red {
      @apply rounded-full bg-salmon-500 h-fit w-full mt-4 font-normal uppercase font-mono tracking-wide shadow-none;
    }
    &.black {
      @apply bg-gray-700 w-full min-h-20;
    }
  }

  div[data-track="product"],
  div[data-track="design"],
  div[data-track="development"] {
    @apply h-full flex-col justify-between pb-10;
    .select-button {
      label {
        @apply select-none rounded-xl inline-block p-4 w-full h-full col-span-1 text-center font-bold transition-colors duration-200 ease-in-out min-h-12 bg-gray-100 text-gray-800 flex flex-col justify-center shadow-md shadow-oyster-300 border-2 border-transparent;
      }
    }
  }

  div[data-track="product"] {
    .select-button {
      label {
        @apply peer-checked:bg-flamingo-200 peer-checked:text-gray-800 peer-checked:border-flamingo-400 peer-checked:shadow-flamingo-300;
      }
    }
  }

  div[data-track="design"] {
    .select-button {
      label {
        @apply peer-checked:bg-pineapple-200 peer-checked:text-gray-800 peer-checked:border-pineapple-400 peer-checked:shadow-pineapple-500;
      }
    }
  }

  div[data-track="development"] {
    .select-button {
      label {
        @apply peer-checked:bg-gray-200 peer-checked:text-gray-800 peer-checked:border-gray-400 peer-checked:shadow-gray-300;
      }
    }
  }
  .rip-component {
    @apply w-full h-fit;
  }
}