
/* Nav styles imported from Index */
body > nav a:not(.bare-nav-link),
.as-nav-link {
  @apply font-mono lg:font-body font-normal text-sm lg:text-base leading-none lg:leading-tight lg:text-right no-underline uppercase py-1 lg:py-2 px-2 lg:px-4 hover:bg-pineapple-400 hover:text-black;

  li:hover > a, summary:hover > p { @apply bg-pineapple-400 lg:bg-transparent; }

  &.active {
    @apply text-center md:bg-transparent hover:bg-pineapple-400 hover:text-black h-fit;
  }
}

nav {
  @apply border-b lg:border-b-0;

  /* General Nav Style */
  #nav-bar {
    .nav-items {
      @apply border-y lg:border-y-0 font-mono; 
      li { 
        @apply w-full lg:w-fit lg:text-center; 
        .nav-details {
          summary {
            @apply text-current cursor-pointer list-none flex flex-row items-center justify-between w-full lg:w-fit px-4 lg:px-2 lg:py-2 py-3;
  
            p { @apply w-fit p-1 lg:p-0; }
          }
          summary:hover > p { @apply bg-pineapple-400 text-gray-900 lg:bg-transparent; }
        }
      }
      a:not(.btn) { 
        @apply w-full lg:w-fit text-left lg:text-center p-4 lg:p-0 lg:bg-transparent;
        .nav-copy { @apply px-1 lg:px-3 lg:py-2; }
      }
      a:hover > span { @apply bg-pineapple-400; }
    }
    #services-subnav {
      @apply border-t lg:absolute left-0 lg:top-[104px] w-full z-20;

      .services-nav-expansion-wrapper { 
        
        .subnav-header { 
          @apply lg:hover:underline underline-offset-4 decoration-4;
          &.nav-link { @apply w-full lg:hover:text-salmon-500; }
        }
        li { 
          @apply hover:bg-pineapple-100 lg:hover:bg-transparent text-left font-mono uppercase lg:font-body lg:normal-case; 
          span { @apply block px-1 lg:px-0; }
        }
        a:hover > span { @apply bg-pineapple-400 lg:bg-transparent; }
      }
      .quick-wins-list {
        @apply divide-y lg:divide-y-0;
        li { 
          @apply lg:hover:text-salmon-500 lg:hover:bg-transparent;
          a { 
            @apply px-4 lg:px-0 lg:py-0 py-4 inline-block w-full hover:bg-pineapple-100 lg:hover:bg-transparent lg:hover:underline underline-offset-4 decoration-4;
            span { @apply w-fit px-1 lg:px-0;}
          }
          a:hover > span { @apply bg-pineapple-400 lg:bg-transparent;}
        }
        a:hover > span { @apply bg-pineapple-400 lg:bg-transparent; }
      }
      .quick-wins {
        @apply border-b lg:border-b-0 lg:bg-transparent;
      }
    }
  }

/* Pink Nav */
  &.nav-pink { 
    @apply bg-flamingo-200 border-flamingo-300 shadow-flamingo-300/50 shadow-lg  lg:shadow-none;
    .nav-items { 
      @apply divide-flamingo-300 border-flamingo-300;
      li { 
        @apply bg-flamingo-200; 
        .nav-details summary { @apply hover:bg-pineapple-100 lg:hover:bg-pineapple-400; }
      }
      a:not(.btn) { @apply hover:bg-pineapple-100; }
      a:hover > span { @apply bg-pineapple-400; }
    }
    #services-subnav {
      @apply bg-flamingo-100 border-flamingo-300;
      .services-nav-expansion-wrapper { 
        @apply divide-flamingo-300 lg:shadow-flamingo-400/50 lg:shadow-lg;
        .subnav-header { 
          @apply decoration-salmon-400;
          &.nav-link { @apply lg:hover:text-salmon-500; }
        }
        li { @apply bg-flamingo-100; }
        a:hover > span { @apply bg-pineapple-400 lg:bg-transparent; }
      }
      .quick-wins-list {
        @apply divide-flamingo-300;

        li { 
          @apply bg-flamingo-100;

          a { @apply hover:bg-pineapple-100 decoration-salmon-400;}
          a:hover > span { @apply bg-pineapple-400 lg:bg-transparent;}
        }
        a:hover > span { @apply bg-pineapple-400 lg:bg-transparent; }
      }
      .quick-wins {
        @apply border-flamingo-300 bg-flamingo-200;
      }
    }
  }

/* White Nav */
  &.nav-white { 
    @apply bg-gray-100 border-gray-100 shadow-gray-300/50 shadow-lg  lg:shadow-none;
    .nav-items { 
      @apply divide-gray-200 border-gray-200 ;
      li { 
        @apply bg-gray-100; 
        .nav-details summary { @apply hover:bg-pineapple-100 lg:hover:bg-pineapple-400; }
      }
      a:not(.btn) { @apply hover:bg-pineapple-100; }
      a:hover > span { @apply bg-pineapple-400; }
    }
    #services-subnav {
      @apply bg-gray-100 border-gray-300;

      .services-nav-expansion-wrapper { 
        @apply divide-gray-200 lg:shadow-gray-300/50 shadow-lg;
        .subnav-header { 
          @apply decoration-salmon-400;
          &.nav-link { @apply lg:hover:text-salmon-500; }
        }
        li { @apply bg-gray-100; }
        a:hover > span { @apply bg-pineapple-400 lg:bg-transparent; }
      }
      .quick-wins-list {
        @apply divide-gray-200;

        li { 
          @apply bg-gray-100;

          a { @apply hover:bg-pineapple-100 decoration-salmon-400;}
          a:hover > span { @apply bg-pineapple-400 lg:bg-transparent;}
        }
        a:hover > span { @apply bg-pineapple-400 lg:bg-transparent; }
      }
      .quick-wins {
        @apply border-gray-200 bg-gray-100;
      }
    }
  }

/* Beige Nav */
  &.nav-beige { 
    @apply bg-beige border-oyster-300 shadow-oyster-300/50 shadow-lg lg:shadow-none;
    .nav-items { 
      @apply divide-oyster-300 border-oyster-300 ;
      li { 
        @apply bg-oyster-200; 
        .nav-details summary { @apply hover:bg-pineapple-100 lg:hover:bg-pineapple-400; }
      }
      a:not(.btn) { @apply hover:bg-pineapple-100; }
      a:hover > span { @apply bg-pineapple-400; }
    }
    #services-subnav {
      @apply bg-oyster-100 border-oyster-300;

      .services-nav-expansion-wrapper { 
        @apply divide-oyster-300 lg:shadow-oyster-300/50 shadow-lg;
        .subnav-header { 
          @apply decoration-salmon-400;
          &.nav-link { @apply lg:hover:text-salmon-500; }
        }
        li { @apply bg-oyster-100; }
        a:hover > span { @apply bg-pineapple-400 lg:bg-transparent; }
      }
      .quick-wins-list {
        @apply divide-oyster-300;

        li { 
          @apply bg-oyster-100;

          a { @apply hover:bg-pineapple-100 decoration-salmon-400;}
          a:hover > span { @apply bg-pineapple-400 lg:bg-transparent;}
        }
        a:hover > span { @apply bg-pineapple-400 lg:bg-transparent; }
      }
      .quick-wins {
        @apply border-oyster-300 bg-oyster-200;
      }
    }
  }

/* Oyster Nav */
  &.nav-oyster { 
    @apply bg-beige border-oyster-300 shadow-oyster-300/50 shadow-lg lg:shadow-none;
    .nav-items { 
      @apply divide-oyster-300 border-oyster-300 ;
      li { 
        @apply bg-oyster-200; 
        .nav-details summary { @apply hover:bg-pineapple-100 lg:hover:bg-pineapple-400; }
      }
      a:not(.btn) { @apply hover:bg-pineapple-100; }
      a:hover > span { @apply bg-pineapple-400; }
    }
    #services-subnav {
      @apply bg-oyster-100 border-oyster-300;

      .services-nav-expansion-wrapper { 
        @apply divide-oyster-300 lg:shadow-oyster-300/50 shadow-lg;
        .subnav-header { 
          @apply decoration-salmon-400;
          &.nav-link { @apply lg:hover:text-salmon-500; }
        }
        li { @apply bg-oyster-100; }
        a:hover > span { @apply bg-pineapple-400 lg:bg-transparent; }
      }
      .quick-wins-list {
        @apply divide-oyster-300;

        li { 
          @apply bg-oyster-100;

          a { @apply hover:bg-pineapple-100 decoration-salmon-400;}
          a:hover > span { @apply bg-pineapple-400 lg:bg-transparent;}
        }
        a:hover > span { @apply bg-pineapple-400 lg:bg-transparent; }
      }
      .quick-wins {
        @apply border-oyster-300 bg-oyster-200;
      }
    }
  }

  /* Black Nav */
  &.nav-black { 
    @apply bg-gray-900 border-gray-600/50 shadow-gray-900/50 shadow-lg lg:shadow-none;
    .nav-items { 
      @apply divide-gray-600/50 border-gray-600/50;
      li { 
        @apply bg-gray-900; 
        .nav-details summary { @apply hover:bg-pineapple-100 lg:hover:bg-pineapple-400; }
      }
      a:not(.btn) { @apply hover:bg-pineapple-100; }
      a:hover > span { @apply bg-pineapple-400; }
    }
    #services-subnav {
      @apply bg-gray-800 border-gray-700;

      .services-nav-expansion-wrapper { 
        @apply divide-gray-600/50 lg:shadow-gray-800/50 shadow-lg;
        .subnav-header { 
          @apply hover:decoration-pineapple-400;
          &.nav-link { @apply lg:hover:text-pineapple-400; }
        }
        li { @apply bg-gray-800; }
        a:hover > span { @apply bg-pineapple-400 text-gray-900 lg:text-white lg:hover:text-pineapple-400 lg:bg-transparent; }
      }
      .quick-wins-list {
        @apply divide-gray-600/50;

        li { 
          @apply bg-gray-800;

          a { @apply hover:bg-pineapple-100 hover:text-pineapple-400 decoration-pineapple-400;}
          a:hover > span { @apply lg:hover:text-pineapple-400;}
        }
        a:hover > span { @apply bg-pineapple-400 lg:bg-transparent; }
      }
      .quick-wins {
        @apply border-gray-600 bg-gray-900;
      }
    }
  }

  /* Gray Nav */
  &.nav-gray { 
    @apply bg-gray-800 border-gray-600 shadow-gray-900/50 shadow-lg lg:shadow-none;
    .nav-items { 
      @apply divide-gray-600 border-gray-600;
      li { 
        @apply bg-gray-800; 
        .nav-details summary { @apply hover:bg-pineapple-100 lg:hover:bg-pineapple-400; }
      }
      a:not(.btn) { @apply hover:bg-pineapple-100; }
      a:hover > span { @apply bg-pineapple-400; }
    }
    #services-subnav {
      @apply bg-gray-700 border-gray-600;

      .services-nav-expansion-wrapper { 
        @apply divide-gray-600/50 lg:shadow-gray-800/50 shadow-lg;
        .subnav-header { 
          @apply hover:text-pineapple-400 hover:decoration-pineapple-400;
          &.nav-link { @apply lg:hover:text-pineapple-500; }
        }
        li { @apply bg-gray-700; }
        a:hover > span { @apply text-gray-900 lg:text-white lg:hover:text-pineapple-400; }
      }
      .quick-wins-list {
        @apply divide-gray-600;

        li { 
          @apply bg-gray-700;

          a { @apply hover:bg-pineapple-100 decoration-pineapple-400;}
          a:hover > span { @apply bg-pineapple-400 lg:bg-transparent;}
        }
        a:hover > span { @apply bg-pineapple-400 lg:bg-transparent; }
      }
      .quick-wins {
        @apply border-gray-600 bg-gray-800;
      }
    }
  }

  /* Salmon Nav */
  &.nav-salmon { 
    @apply bg-salmon-500 border-salmon-500 shadow-salmon-700/50 shadow-lg lg:shadow-none;
    .nav-items { 
      @apply divide-salmon-400 border-salmon-400;
      li { 
        @apply bg-salmon-500; 
        .nav-details summary { @apply hover:bg-pineapple-100 lg:hover:bg-pineapple-400; }
      }
      a:not(.btn) { @apply hover:bg-pineapple-100; }
      a:hover > span { @apply bg-pineapple-400; }
    }
    #services-subnav {
      @apply bg-salmon-600 border-salmon-500;

      .services-nav-expansion-wrapper { 
        @apply divide-salmon-500 lg:shadow-salmon-700/50 shadow-lg;
        .subnav-header { 
          @apply hover:decoration-pineapple-400;
          &.nav-link { @apply lg:hover:text-pineapple-500; }
        }
        li { @apply bg-salmon-600; }
        a:hover > span { @apply bg-pineapple-400 text-gray-900 lg:text-white lg:bg-transparent; }
      }
      .quick-wins-list {
        @apply divide-salmon-500;

        li { 
          @apply bg-salmon-600;

          a { @apply hover:bg-pineapple-100 decoration-pineapple-400;}
          a:hover > span { @apply bg-pineapple-400 lg:bg-transparent;}
        }
        a:hover > span { @apply bg-pineapple-400 lg:bg-transparent; }
      }
      .quick-wins {
        @apply border-salmon-600 bg-salmon-500;
      }
    }
  }

  /* Yellow Nav */
  &.nav-yellow { 
    @apply bg-pineapple-400 border-pineapple-500 shadow-pineapple-800/50 shadow-lg lg:shadow-none;
    .nav-items { 
      @apply divide-pineapple-500 border-pineapple-500;
      li { 
        @apply bg-pineapple-400; 
        .nav-details summary { @apply hover:bg-pineapple-100 lg:hover:bg-pineapple-400; }
      }
      a:not(.btn) { @apply hover:bg-pineapple-100; }
      a:hover > span { @apply bg-pineapple-400; }
    }
    #services-subnav {
      @apply bg-pineapple-300 border-pineapple-500;

      .services-nav-expansion-wrapper { 
        @apply divide-pineapple-500 lg:shadow-pineapple-500/50 shadow-lg;
        .subnav-header { 
          @apply hover:decoration-pineapple-400;
          &.nav-link { @apply lg:hover:text-pineapple-500; }
        }
        li { @apply bg-pineapple-300; }
        a:hover > span { @apply bg-pineapple-300 text-gray-900 lg:text-white lg:bg-transparent; }
      }
      .quick-wins-list {
        @apply divide-pineapple-500;

        li { 
          @apply bg-pineapple-300;

          a { @apply hover:bg-pineapple-100 decoration-pineapple-400;}
          a:hover > span { @apply bg-pineapple-300 lg:bg-transparent;}
        }
        a:hover > span { @apply bg-pineapple-300 lg:bg-transparent; }
      }
      .quick-wins {
        @apply border-pineapple-600 bg-pineapple-400;
      }
    }
  }

  &.nav-white,
  &.nav-beige,
  &.nav-pink,
  &.nav-yellow,
  &.nav-oyster {
    @apply text-center w-full text-black;
    .nav-link {
      @apply block lg:text-center w-full lg:w-fit p-4 lg:p-0 text-left font-mono;
      &.active { @apply border-l-4 border-salmon-500 lg:border-l-0;}
      &.active .nav-copy {
        @apply w-fit hover:bg-pineapple-400 lg:underline underline-offset-8 decoration-salmon-500 decoration-4 ml-[-4px] lg:ml-0;
      }
    }
  }

  &.nav-black,
  &.nav-salmon,
  &.nav-gray {
    @apply text-center w-full text-white;
    .nav-link {
      @apply block lg:text-center w-full lg:w-fit p-4 lg:p-0 text-left font-mono;
      &.active { @apply border-l-4 border-pineapple-500 lg:border-l-0;}
      &.active .nav-copy {
        @apply hover:bg-pineapple-400 hover:!text-black lg:underline underline-offset-8 decoration-pineapple-400 decoration-4 ml-[-4px] lg:ml-0;
      }
      &.as-nav-link {
        @apply text-white hover:bg-pineapple-400 hover:!text-black;
      }
    }
  }

  #menu-icon-wrapper {
    @apply w-20 h-auto;
  }
  &.nav--open {
    .menu-icon.open { 
      @apply hidden py-6;
    }
  }

  &.nav--closed {
    ul {
      @apply hidden lg:flex flex-col lg:flex-row justify-between items-center w-full;
    }
    .menu-icon.close {
      @apply hidden py-6;
    }
  }

  #nav-bar .contact-button-wrapper { @apply px-4 py-2; }
}

.services-nav-expansion-wrapper {
  .subnav-header {
    @apply font-mono text-sm lg:text-base text-left uppercase pb-1 px-1 lg:mb-4 hover:bg-pineapple-400 lg:hover:bg-transparent lg:hover:underline hover:underline-offset-8 decoration-4 gap-2;

    &.nav-link {
      @apply flex items-center w-fit;
    }
    .nav-arrow-right {
      @apply font-mono text-base uppercase inline-flex items-center justify-center inline-block;
    }
  }

  .quick-wins-list {
    @apply flex flex-col w-full items-start lg:gap-2 text-sm lg:text-base;
    li { @apply hover:bg-pineapple-100 lg:hover:bg-transparent w-full lg:p-0;}
  }
}


/* --------- quick wins/nav-details --------- */

details {
  .nav-details[open] summary {
    @apply bg-pineapple-400 text-black;
    
  }
  .nav-details:not(> summary) {
    @apply !text-red-400;
  }
}

.nav-details {
  &:hover > summary { @apply bg-pineapple-400 text-black; }
  .sub-nav {
    @apply flex flex-col justify-start w-full md:w-[9rem] font-body flex flex-col absolute max-sm:bottom-[-6.5rem] bottom-[-7rem] left-[0.5rem] bg-gray-100 z-[50] rounded-md gap-0;
  }
}

.nav-details summary span {
  &::after {
    @apply inline-block;
  }
}

.nav-details:not(.nav-details[open]) summary span::after {
  content: "\25BC";
}

.nav-details[open] summary span::after {
  content: "\25B2";
}

.nav-details summary {
  @apply text-nowrap flex flex-row;
}

/* hide the disclosure triangle in Safari */
details.nav-details summary::-webkit-details-marker {
  @apply hidden;
}

/* --------- end quick wins/nav-details --------- */
